import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { TranslateFunction, useI18N } from '@tm/client-form/src/hooks/useI18n'
import { SkipButtonState } from './useSkipButton'

export type SkipButtonProps = SkipButtonState

const getButtonText = (name: string, t: TranslateFunction) => {
  switch (name) {
    case 'noVideoRecord':
      return t('review.noVideoRecord')
    case 'noNegativeContact':
      return t('contactInfo.noContactInfo')
  }

  return t('review.noPublicConsent')
}

export function SkipButton(props: SkipButtonProps) {
  const { skipField, handleSkipClick } = props
  const { t } = useI18N()
  const theme = useTheme()

  if (!skipField) return null

  const buttonText = getButtonText(skipField.name, t)

  return (
    <Stack justifyContent="center" alignItems="center">
      <Button
        variant="text"
        onClick={handleSkipClick}
        sx={{
          ...theme.typography.body2,
          textDecoration: 'underline',
          color: 'text.secondary',
        }}>
        {buttonText}
      </Button>
    </Stack>
  )
}
