import queryString from 'query-string'
import { useMemo } from 'react'
import { CampaignSurveyLoader, CampaignSurveyLoaderProps } from './components/campaign/CampaignSurveyLoader'
import { ThemeProvider } from './components/campaign/Theme'
import './misc/styled'

/**
 * Get relevant survey info from url and localstorage
 */
function useSurveyProps(): CampaignSurveyLoaderProps {
  return useMemo(() => {
    // Get survey id from path
    const pathVariables = window.location.pathname.split('/').filter(Boolean)
    const surveyId = pathVariables[pathVariables.length - 1]

    // Get invitation id and source from query string
    const { i, source, sourceUrl, ...queryParams } = queryString.parse(location.search)

    // Get custom params from query string
    const regex = '^[p][0-9]+$'
    const customParams = Object.keys(queryParams).reduce((params, key) => {
      if (RegExp(regex).exec(key)) params[key] = queryParams[key]
      return params
    }, {})

    // Get answer id from localstorage, use try/catch because some browsers don't allow localstorage access
    let answerId: string | undefined
    try {
      answerId = localStorage.getItem(`${surveyId}_answerId`) || undefined
    } catch {
      answerId = undefined
    }

    return {
      surveyId,
      invitationId: typeof i === 'string' ? i : undefined,
      source: typeof source === 'string' ? source : undefined,
      sourceUrl: typeof sourceUrl === 'string' ? sourceUrl : undefined,
      customParams,
      answerId,
    }
  }, [])
}

const CampaignRoot = () => {
  const props = useSurveyProps()
  return (
    <ThemeProvider>
      <CampaignSurveyLoader {...props} />
    </ThemeProvider>
  )
}

export default CampaignRoot
