import { tmary } from '@tm/client-embed/src/common/window'
import { ClientSurveyAnswer, PostSurveyResponse, StepValues } from '@tm/shared-lib/src/api'
import { HiddenFields } from '@tm/types/common/hiddenFields'
import { useCallback, useEffect, useRef } from 'react'

export type SubmitHandler = (data: ClientSurveyAnswer) => Promise<PostSurveyResponse>

interface GlobalWindow extends Window {
  dataLayer?: Record<string, unknown>[]
}

export function useSurveySubmit(options: {
  stepId: string
  eventId: string
  invitationId?: string
  source?: string
  sourceUrl?: string
  answerId?: string
  surveyId: string
  customParams?: Record<string, string>
  submitOverride?: SubmitHandler
  submitDatalayerContext?: Record<string, string>
  hiddenFields?: HiddenFields
  conversionId?: string
  preview: boolean
  previewValues?: StepValues
  progressDirection?: 'back' | 'forward'
}): (values: StepValues) => Promise<PostSurveyResponse> {
  const abort = useRef<AbortController>(new AbortController())

  useEffect(() => {
    const abortController = abort.current
    return () => {
      abortController.abort()
    }
  }, [])

  const submit = useCallback(
    (valuesToSubmit: StepValues): Promise<PostSurveyResponse> => {
      const data: ClientSurveyAnswer = {
        stepId: options.stepId,
        stepValues: valuesToSubmit,
        eventId: options.eventId,
        answerId: options.answerId,
        invitationId: options.invitationId,
        source: options.source,
        sourceUrl: options.sourceUrl,
        customParams: options.customParams,
        hiddenFields: options.hiddenFields,
        preview: options.preview,
        previewValues: options.previewValues,
        progressDirection: options.progressDirection,
      }
      const globalWindow = window as unknown as GlobalWindow
      globalWindow.dataLayer = globalWindow.dataLayer || []
      globalWindow.dataLayer.push({ event: 'trustmary.FORM_SUBMIT', ...options.submitDatalayerContext })

      const { conversionId, submitDatalayerContext = {} } = options

      if (conversionId) tmary('conversion', { id: conversionId })
      if (submitDatalayerContext.widget_id) tmary('formSubmit', submitDatalayerContext.widget_id)

      // We need to be able to run submitOverride when developing widgets
      if (typeof options.submitOverride === 'function') return options.submitOverride(data)
      if (!process.env.API_DOMAIN) throw new Error('API_DOMAIN missing')
      return fetch(`${process.env.API_DOMAIN}/collect/v2/survey/${options.surveyId}`, {
        method: 'POST',
        body: JSON.stringify(data),
        signal: abort.current.signal,
      }).then(res => res.json() as Promise<PostSurveyResponse>)
    },
    [options]
  )

  return submit
}
