import Button from '@mui/material/Button'
import { useCampaign } from '../../hooks/useCampaign'
import { useI18N } from '../../hooks/useI18n'
import { CampaignProgressBar } from './CampaignProgressBar'

type CampaignSubmitProps = {
  onClick: () => void
  text?: string
  disabled?: boolean
  // Used in editor to give inline step number
  overridePage?: number
}

export function CampaignSubmit(props: CampaignSubmitProps) {
  const { onClick, text, disabled } = props
  const { buttonSx } = useCampaign()
  const { t } = useI18N()

  return (
    <>
      <CampaignProgressBar position="after" overridePage={props.overridePage} />
      <Button onClick={onClick} disabled={disabled} size="large" variant="contained" sx={buttonSx}>
        {text || t('form.defaultSubmit')}
      </Button>
    </>
  )
}
