import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useCampaign } from '../../../hooks/useCampaign'
import { CampaignBackButton } from '../CampaignBackButton'
import { CampaignFooter } from '../CampaignFooter'
import { CampaignProgressBar } from '../CampaignProgressBar'
import { CampaignLanguageSwitcher } from './CampaignLanguageSwitcher'
import { CampaignPageOverlay } from './CampaignPageOverlay'

type CampaignPageProps = {
  children: React.ReactNode
  absolutePosition?: boolean
  staticPosition?: boolean
  hideLanguageSwitcher?: boolean
  sx?: SxProps
  isSubmitting?: boolean
  // Used in editor to give inline step number
  overridePage?: number
}

function CampaignHeader({ isSubmitting }: { isSubmitting?: boolean }) {
  const { logo, headerText } = useCampaign()
  const theme = useTheme()

  return (
    <Stack
      sx={{
        px: 3,
        py: 2,
        alignItems: 'center',
        justifyContent: 'center',
        position: {
          xs: 'fixed',
          sm: 'static',
        },
        top: 0,
        left: 0,
        width: '100%',
        height: {
          xs: 72,
          sm: 'auto',
        },
        bgcolor: {
          xs: 'rgba(255, 255, 255, 1)',
          sm: 'rgba(255, 255, 255, .95)',
        },
        borderTopLeftRadius: {
          sm: 16,
        },
        borderTopRightRadius: {
          sm: 16,
        },
        backdropFilter: 'blur(16px)',
        zIndex: 2,
        boxShadow: {
          xs: theme.customShadows.card,
          sm: 'none',
        },
      }}>
      {logo ? (
        <Box component="img" src={logo} sx={{ height: { xs: 40, sm: 60 } }} />
      ) : (
        <Typography variant="subtitle1" textAlign="center">
          {headerText}
        </Typography>
      )}
      <CampaignBackButton isSubmitting={isSubmitting} />
    </Stack>
  )
}

function CampaignPageFull(props: CampaignPageProps) {
  const { pageBackgroundColor, loading, primaryColor, whitelabel, logo, headerText } = useCampaign()
  const theme = useTheme()

  const showHeader = !!logo || !!headerText

  return (
    <Stack
      sx={{
        position: props.staticPosition ? 'relative' : props.absolutePosition ? 'absolute' : 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: pageBackgroundColor,
        overflow: 'auto',
        transition: '.2s background-color ease-in-out',
        px: {
          xs: 0,
          sm: 1,
        },
        py: {
          xs: 0,
          sm: 2,
        },
        ...props.sx,
      }}>
      <Stack
        gap={{
          sm: 3,
        }}
        sx={{
          width: '100%',
          maxWidth: {
            sm: 640,
          },
          margin: {
            sm: 'auto',
          },
          height: {
            xs: '100%',
            sm: 'auto',
          },
          pt: showHeader
            ? {
                xs: 9, // Offset for fixed header
                sm: 0,
              }
            : 0,
        }}>
        <CampaignPageOverlay type="circles" />
        <Stack
          sx={{
            borderRadius: {
              xs: 0,
              sm: 1,
            },
            boxShadow: {
              sm: theme.customShadows.card,
            },
            flexGrow: 1,
            position: 'relative',
            zIndex: 2,
          }}>
          {showHeader && <CampaignHeader isSubmitting={props.isSubmitting} />}
          <Stack
            sx={{
              background: {
                sm: 'rgba(255, 255, 255, .8)',
              },
              backdropFilter: {
                sm: 'blur(16px)',
              },
              ...(showHeader
                ? {
                    borderBottomLeftRadius: {
                      sm: 16,
                    },
                    borderBottomRightRadius: {
                      sm: 16,
                    },
                  }
                : {
                    borderRadius: {
                      sm: 2,
                    },
                  }),
              p: {
                xs: 2,
                sm: 3,
              },
              position: 'relative',
              flexGrow: 1,
              overflow: 'auto',
              justifyContent: 'center',
            }}>
            {loading && (
              <LinearProgress
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: primaryColor,
                  },
                }}
              />
            )}
            <Stack
              sx={{
                [theme.breakpoints.down('sm')]: {
                  background: 'rgba(255, 255, 255, .8)',
                  backdropFilter: 'blur(16px)',
                  borderRadius: 2,
                  p: 2,
                  pt: 3,
                  boxShadow: theme.customShadows.card,
                },
              }}>
              <CampaignProgressBar position="before" overridePage={props.overridePage} />
              {props.children}
            </Stack>
          </Stack>
        </Stack>
        {!props.hideLanguageSwitcher && <CampaignLanguageSwitcher />}
        {!whitelabel && <CampaignFooter />}
      </Stack>
    </Stack>
  )
}

function CampaignPageCompact(props: CampaignPageProps) {
  const { loading, primaryColor, whitelabel } = useCampaign()

  return (
    <Stack
      gap={2}
      sx={{
        bgcolor: 'background.neutral',
        borderRadius: 1,
      }}>
      <Stack
        sx={{
          borderRadius: {
            sm: 1,
          },
          p: {
            xs: 2,
            sm: 3,
          },
          position: 'relative',
          flexGrow: 1,
          overflow: 'auto',
          justifyContent: 'center',
        }}>
        <CampaignBackButton isSubmitting={props.isSubmitting} isCompact />
        {loading && (
          <LinearProgress
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              '& .MuiLinearProgress-bar': {
                backgroundColor: primaryColor,
              },
            }}
          />
        )}
        {props.children}
      </Stack>
      {!whitelabel && <CampaignFooter />}
    </Stack>
  )
}

export function CampaignPage(props: CampaignPageProps) {
  const { campaignFrame } = useCampaign()
  if (campaignFrame.inFrame && campaignFrame.frameView !== 'full')
    return <CampaignPageCompact {...props}>{props.children}</CampaignPageCompact>

  return <CampaignPageFull {...props}>{props.children}</CampaignPageFull>
}
