import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'
import { ReactNode } from 'react'

type CampaignGroupProps = {
  children: ReactNode
  plain?: boolean
}

export function CampaignGroup(props: CampaignGroupProps) {
  const theme = useTheme()
  const sx: SxProps | undefined = !props.plain
    ? {
        bgcolor: 'rgba(255, 255, 255, .8)',
        borderRadius: 1,
        boxShadow: theme.customShadows.card,
        border: '1px solid #fff',
        p: {
          xs: 2,
          sm: 3,
        },
      }
    : {
        '.MuiInputBase-root': {
          bgcolor: 'rgba(255, 255, 255, .8)',
          boxShadow: theme.customShadows.card,
          border: '1px solid #fff',
        },
        '.MuiInputBase-root.Mui-focused, .MuiInputBase-root:hover': {
          bgcolor: 'rgba(255, 255, 255, 1)',
        },
        '.MuiInputBase-root.Mui-disabled': {
          bgcolor: 'rgba(255, 255, 255, .8)',
        },
      }

  return (
    <Stack gap={2} sx={sx}>
      {props.children}
    </Stack>
  )
}
