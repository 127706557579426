import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'
import { useSurveyState } from '@tm/client-form/src/hooks/useSurveyState'
import { Icon } from '@trustmary/ui/atomic/Icon'
import { useCallback } from 'react'

export function CampaignLanguageSwitcher() {
  const { lang, surveyLanguages } = useI18N()
  const [state, dispatch] = useSurveyState()

  const onLangChange = useCallback(
    (event: SelectChangeEvent) => dispatch({ type: 'set language', lang: event.target.value }),
    [dispatch]
  )

  if (state.type !== 'in progress') return null
  if (surveyLanguages.length < 2) return null

  return (
    <Stack
      direction="row"
      sx={{
        py: 2,
        px: 3,
        background: 'rgba(255, 255, 255, .8)',
        backdropFilter: 'blur(16px)',
        borderRadius: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1,
      }}>
      <Icon
        icon={faLanguage}
        sx={{
          color: 'text.secondary',
        }}
      />
      <Select
        value={lang}
        onChange={onLangChange}
        size="small"
        variant="standard"
        sx={{
          '&:before': {
            border: 'none',
          },
          '.MuiInputBase-input': {
            py: 0.5,
          },
        }}>
        {surveyLanguages.map(language => {
          const { lang, name } = language

          return (
            <MenuItem value={lang} key={lang}>
              {name}
            </MenuItem>
          )
        })}
      </Select>
    </Stack>
  )
}
