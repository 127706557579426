import { PublicConsentType } from '@tm/types/db/tables'
import { Action } from '../actions/action'
import { surveyClosedState } from './closed'
import { permanentError } from './error'
import { InProgressStateImpl } from './inProgress'
import { surveyNotFoundState } from './notFound'
import type { BaseState, State } from './state'

export interface LoadingState extends BaseState {
  type: 'loading get'
  surveyId: string
  invitationId?: string
  source?: string
  sourceUrl?: string
  answerId?: string
  overrideLang?: string
  customParams?: Record<string, string>
}

export class LoadingStateImpl implements LoadingState {
  type = 'loading get' as const
  surveyId: string
  invitationId?: string
  source?: string
  sourceUrl?: string
  answerId?: string
  overrideLang?: string
  customParams?: Record<string, string>
  publicConsent?: PublicConsentType
  constructor(args: {
    surveyId: string
    invitationId?: string
    source?: string
    sourceUrl?: string
    answerId?: string
    overrideLang?: string
    customParams?: Record<string, string>
    publicConsent?: PublicConsentType
  }) {
    this.surveyId = args.surveyId
    this.invitationId = args.invitationId
    this.source = args.source
    this.sourceUrl = args.sourceUrl
    this.answerId = args.answerId
    this.overrideLang = args.overrideLang
    this.customParams = args.customParams
    this.publicConsent = args.publicConsent
  }

  processAction(action: Action): State {
    if (action.type === 'get response') {
      switch (action.data.type) {
        case 'step':
          return new InProgressStateImpl({
            values: action.data.surveyValues || {},
            surveyId: this.surveyId,
            eventId: action.data.eventId,
            step: action.data.step,
            meta: action.data.meta,
            features: action.data.features,
            answerId: action.data.answerId,
            invitationId: this.invitationId,
            source: this.source,
            sourceUrl: this.sourceUrl,
            overrideLang: this.overrideLang,
            customParams: this.customParams,
            publicConsent: action.data.publicConsent,
            isCampaignSurvey: action.data.isCampaignSurvey,
          }).processDefaultvalues()
        case 'error': {
          switch (action.data.errorCode) {
            case 'surveyNotFound':
              return surveyNotFoundState
            case 'surveyClosed':
              return surveyClosedState
          }
          return this
        }
      }
    } else if (action.type === 'loading error') {
      return permanentError
    }
    return this
  }
}
