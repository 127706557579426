import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Icon from '@trustmary/icons'
import { styled } from 'goober'
import React from 'react'

const Wrapper = styled('div')<{ isEmbed?: boolean }>`
  position: absolute;
  ${({ isEmbed }) => (isEmbed ? 'right: 8px' : 'left: 16px')};
  top: ${({ isEmbed }) => (isEmbed ? '8px' : '16px')};
  z-index: 3;
`

const Button = styled('button')`
  background: transparent;
  border: none;
  color: #aaa;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #888;
  }
  &:disabled {
    cursor: default;
    color: #ddd;
  }
`

export const SurveyBackButton = ({ disabled, isEmbed }: { disabled: boolean; isEmbed?: boolean }) => {
  return (
    <Wrapper isEmbed={isEmbed}>
      <Button type="submit" data-previous={true} disabled={disabled}>
        <Icon icon={faArrowLeft} />
      </Button>
    </Wrapper>
  )
}
