import { useTitle } from '@tm/client-form/src/hooks/useTitle'
import { Field } from '@tm/shared-lib/src/field'
import { useCallback, useMemo } from 'react'
import { useI18N } from '../../../../hooks/useI18n'
import { useConsentField } from '../../ConsentField/useConsentField'
import { useSkipButton } from '../common/SkipButton/useSkipButton'
import { ViewProps } from '../types'
import { FeedbackFieldName, FeedbackFieldNames, FeedbackView } from './FeedbackView'

const isFeedbackFieldName = (name: string): name is FeedbackFieldName =>
  FeedbackFieldNames.includes(name as FeedbackFieldName)

/**
 * Create map
 */
const useFeedbackFields = (stepFields: Field[]) => {
  return useMemo(() => {
    const feedbackFields: Map<FeedbackFieldName, Field> = new Map()
    for (const field of stepFields) {
      if (field.name === 'feedback_comment') {
        feedbackFields.set(field.name, field)
      }

      if ('contactFields' in field && field.contactFields === true && 'fields' in field) {
        const contactFields = field.fields || []
        for (const field of contactFields) {
          if (isFeedbackFieldName(field.name)) feedbackFields.set(field.name, field)
        }
      }
    }

    return feedbackFields
  }, [stepFields])
}

const getLabelKey = (fields: Map<FeedbackFieldName, Field>, name: FeedbackFieldName, defaultKey: string) => {
  const field = fields.get(name)
  return field && 'labelKey' in field && field.labelKey ? field.labelKey : defaultKey
}

const getFeedbackTitle = (field: Field | undefined, lang: string) => {
  if (!field) return ''
  if ('label' in field && field.label) return field.label[lang]
  return ''
}

const getFeedbackDescription = (field: Field | undefined, lang: string) => {
  if (!field) return ''
  if ('description' in field && field.description) return field.description[lang]
  return ''
}

export function FeedbackLoader(props: ViewProps) {
  const { state, submitForm, isSubmitting, dispatch, validationErrors } = props
  const { t } = useI18N()
  const fields = useFeedbackFields(state.step.fields || [])

  const feedbackValues = useMemo(() => {
    const feedbackValues: Record<FeedbackFieldName, string> = {
      feedback_comment: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
    }
    for (const [name, field] of fields) {
      if (field.system) {
        const fieldValue = state.values[name]
        if (typeof fieldValue === 'string') feedbackValues[name] = fieldValue
      } else {
        const customValue = state.values?.custom?.[name]
        if (typeof customValue === 'string') feedbackValues[name] = customValue
      }
    }

    return feedbackValues
  }, [state.values, fields])

  const labels = {
    feedback_comment: t(getLabelKey(fields, 'feedback_comment', 'contactInfo.message')),
    contactName: t(getLabelKey(fields, 'contactName', 'contactInfo.name')),
    contactEmail: t(getLabelKey(fields, 'contactEmail', 'contactInfo.email')),
    contactPhone: t(getLabelKey(fields, 'contactPhone', 'contactInfo.phone')),
  }

  const onFeedbackChange = useCallback(
    (name: FeedbackFieldName, value: string) => {
      dispatch({ type: 'set value', field: { name, system: !!fields.get(name)?.system }, value })
    },
    [dispatch, fields]
  )

  const feedbackValidationErrors = useMemo(() => {
    const errors: Record<FeedbackFieldName, string[] | undefined> = {
      feedback_comment: undefined,
      contactName: undefined,
      contactEmail: undefined,
      contactPhone: undefined,
    }

    for (const [name] of fields) {
      const fieldErrors = validationErrors[name]
      if (Array.isArray(fieldErrors)) errors[name] = fieldErrors.map(error => t(error))
    }

    return errors
  }, [validationErrors, fields, t])

  /**
   * Create array of required fields so we can show asterisk in ui
   */
  const requiredFields = useMemo(() => {
    const requiredFields: FeedbackFieldName[] = []

    for (const [name, field] of fields.entries()) {
      if (field.validation?.includes('required')) requiredFields.push(name)
    }

    return requiredFields
  }, [fields])

  const consentFieldProps = useConsentField({ state, dispatch, validationErrors })

  const title = getFeedbackTitle(fields.get('feedback_comment'), state.lang)
  useTitle(title)

  const skipProps = useSkipButton(props)

  return (
    <FeedbackView
      values={feedbackValues}
      isSubmitting={isSubmitting}
      onSubmit={submitForm}
      labels={labels}
      onChange={onFeedbackChange}
      title={title}
      description={getFeedbackDescription(fields.get('feedback_comment'), state.lang)}
      errors={feedbackValidationErrors}
      requiredFields={requiredFields}
      consentFieldProps={consentFieldProps}
      skipProps={skipProps}
    />
  )
}
