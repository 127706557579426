import React, { useEffect, useMemo } from 'react'
import { CampaignProvider } from '../../context/campaignContext'
import { SurveyStateContext } from '../../context/surveyStateContext'
import { TranslationsContext } from '../../context/translationsContext'
import { useCampaign } from '../../hooks/useCampaign'
import { useFieldsToDisplay } from '../../hooks/useFieldsToDisplay'
import { InProgressState } from '../../hooks/useSurvey/states/inProgress'
import { State } from '../../hooks/useSurvey/states/state'
import { useSurvey } from '../../hooks/useSurvey/useSurvey'
import { PassProps, SurveyForm } from '../survey/SurveyForm'
import { CampaignInfo } from './CampaignInfo'
import { CampaignPage } from './CampaignPage'
import { CampaignPreviewInfo } from './CampaignPreviewInfo'
import { CampaignView } from './CampaignView'

export interface CampaignSurveyLoaderProps {
  surveyId: string
  invitationId?: string
  source?: string
  sourceUrl?: string
  answerId?: string
  overrideLang?: string
  customParams?: Record<string, string>
}

interface InnerProps {
  state: State
  passProps?: PassProps
}

export function SurveyInner({ state, passProps }: InnerProps) {
  // When state changes, resize the iframe
  const { campaignFrame } = useCampaign()
  useEffect(() => campaignFrame.onResize(), [state])

  switch (state.type) {
    case 'error':
    case 'closed':
    case 'not found':
    case 'loading get':
      return <CampaignInfo type={state.type} />
    case 'in progress':
      return passProps ? <CampaignView passProps={passProps} /> : null
  }
}

const CampaignPageWithForm = ({ state, dispatch }: { state: InProgressState; dispatch: PassProps['dispatch'] }) => {
  const fieldsToDisplay = useFieldsToDisplay(state)
  return (
    <SurveyForm state={state} fields={fieldsToDisplay} dispatch={dispatch}>
      {passProps => (
        <CampaignPage isSubmitting={passProps.submitStatus !== 'none'}>
          <SurveyInner passProps={passProps} state={state} />
        </CampaignPage>
      )}
    </SurveyForm>
  )
}

export function CampaignSurveyLoader(props: CampaignSurveyLoaderProps): JSX.Element {
  const { surveyId, invitationId, source, sourceUrl, answerId, overrideLang, customParams } = props

  const options = useMemo(
    () => ({ invitationId, source, sourceUrl, answerId, overrideLang, customParams }),
    [invitationId, source, sourceUrl, answerId, overrideLang, customParams]
  )

  const [state, dispatch] = useSurvey(surveyId, options)

  const lang = 'lang' in state ? state.lang : 'en'
  const surveyStateValue: [State, typeof dispatch] = useMemo(() => [state, dispatch], [state, dispatch])
  const translationValue = useMemo(
    () => ({ surveyLanguages: 'meta' in state ? state.meta.languages : [], lang }),
    [state, lang]
  )

  return (
    <CampaignProvider state={state}>
      <SurveyStateContext.Provider value={surveyStateValue}>
        <TranslationsContext.Provider value={translationValue}>
          {state.type === 'in progress' ? (
            /**
             * This separation is done so that survey's back button can be placed in to the campaign's top bar.
             * Without it, the button would be outside of the survey form context and be unable to submit
             * the form.
             */
            <CampaignPageWithForm state={state} dispatch={dispatch} />
          ) : (
            <CampaignPage>
              <SurveyInner state={state} />
            </CampaignPage>
          )}
          <CampaignPreviewInfo />
        </TranslationsContext.Provider>
      </SurveyStateContext.Provider>
    </CampaignProvider>
  )
}
