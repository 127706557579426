import { faClose } from '@fortawesome/free-solid-svg-icons/faClose'
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage'
import { Box, FormLabel, Input, Paper, Stack, Typography } from '@mui/material'
import useI18N from '@tm/client-form/src/hooks/useI18n'
import { FontAwesomeSvgIcon } from '@tm/ui/composite/FontAwesomeSvgIcon'

import { useTheme } from '@mui/material/styles'
import { useSurveyFile } from '@tm/client-form/src/hooks/useSurveyFile'
import { CustomValue } from '@tm/shared-lib/src/api'
import { ImageField } from '@tm/shared-lib/src/field'
import { InputProps } from '../types'
import { useFileField } from './useFileField'

const SHARE_DOMAIN = process.env.SHARE_DOMAIN as string

function ImagePreview(props: { file?: File | null; value?: CustomValue }) {
  const preview = props.file
    ? URL.createObjectURL(props.file)
    : typeof props.value === 'string'
    ? `${SHARE_DOMAIN}/${props.value}`
    : null

  if (!preview) return null
  return (
    <Box
      component="img"
      src={preview}
      sx={{
        display: 'block',
        height: 120,
        width: '100%',
        objectFit: 'contain',
      }}
    />
  )
}

export function ImageInput(props: InputProps<ImageField>) {
  const { field, value, onChange, isSubmitting } = props
  const [file, setFile] = useSurveyFile<File>(field)
  const { t } = useI18N()
  const theme = useTheme()

  const fileTypes = '.jpg,.jpeg,.png,.gif,.svg'

  const { error, dragOver, onFileChange, onDragOver, onDragLeave, onDrop, clearFieldValue, maxSizeText } = useFileField(
    {
      onChange,
      setFile,
      fileTypes,
    }
  )

  const hasPreview = !!file || !!value

  return (
    <Paper
      sx={{
        padding: 1,
        bgcolor: dragOver ? 'background.paper' : 'rgba(255, 255, 255, .8)',
        borderRadius: 1,
        position: 'relative',
        border: '1px solid #fff',
        boxShadow: dragOver ? 'grey 0 0 8px' : theme.customShadows.card,
      }}>
      <Stack onDragEnter={onDragOver} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop}>
        <Input
          inputProps={{
            accept: fileTypes,
          }}
          sx={{ display: 'none' }}
          id={`file-input-${field.name}`}
          type="file"
          disabled={isSubmitting}
          onChange={onFileChange}
        />
        {hasPreview && (
          <Box sx={{ height: 120, position: 'relative' }}>
            <ImagePreview file={file} value={value} />
          </Box>
        )}
        {hasPreview ? (
          <FontAwesomeSvgIcon
            icon={faClose}
            onClick={clearFieldValue}
            data-testid="file-clear-value"
            sx={{
              cursor: 'pointer',
              backgroundColor: '#0000007a',
              color: 'white',
              borderRadius: '50%',
              m: 0,
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          />
        ) : (
          <Stack gap={1} p={{ xs: 1, md: 3 }} alignItems="center" height={120} justifyContent="center">
            <Box sx={{ pointerEvents: 'none' }}>
              <FontAwesomeSvgIcon
                icon={faImage}
                sx={{
                  fontSize: 20,
                  color: 'text.secondary',
                }}
              />
            </Box>
            <Stack alignItems="center">
              <Typography variant="body2" color="text.secondary">
                {t('file.dropFileHere')}{' '}
                <FormLabel htmlFor={`file-input-${field.name}`}>
                  <Typography
                    component="a"
                    role="button"
                    variant="body2"
                    color="primary.main"
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {t('file.browse')}
                  </Typography>
                </FormLabel>
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {fileTypes} / max. {maxSizeText}mb
              </Typography>
            </Stack>
          </Stack>
        )}
        {!!error.length && (
          <Typography variant="caption" color="error.main">
            {error}
          </Typography>
        )}
      </Stack>
    </Paper>
  )
}
