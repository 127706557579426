import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material/styles'
import { useCampaign } from '@tm/client-form/src/hooks/useCampaign'
import useI18n from '@tm/client-form/src/hooks/useI18n'

export function CampaignPreviewInfo() {
  const { campaignFrame } = useCampaign()
  const { t } = useI18n()
  const theme = useTheme()

  // In frame, don't show the preview info because it's in the way
  if (campaignFrame.inFrame) return null

  const isPreview = window.location.search.includes('preview=1')
  if (!isPreview) return null

  return (
    <Chip
      label={t('previewInfo', 'This is a preview')}
      variant="soft"
      color="info"
      sx={{
        position: 'fixed',
        top: 20,
        right: 8,
        [theme.breakpoints.up(767)]: {
          right: 'auto',
          left: 16,
          bgcolor: '#dff1fb',
          top: 16,
        },
      }}
    />
  )
}
