import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { CampaignGroup } from '@tm/client-form/src/components/campaign/CampaignGroup'
import { UseTextReview } from './useTextReview'

type TextReviewViewProps = UseTextReview

export function TextReviewView(props: TextReviewViewProps) {
  const { commentValue, onCommentChange, commentError, label, description, inputLabel, hasTextReview } = props

  if (!hasTextReview) return null

  return (
    <Stack gap={2} mb={2}>
      <Stack gap={2}>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body1" whiteSpace="pre-wrap">
          {description}
        </Typography>
      </Stack>
      <CampaignGroup plain>
        <TextField
          id="q-comment"
          label={inputLabel}
          value={commentValue}
          error={!!commentError}
          helperText={commentError}
          onChange={event => onCommentChange(event.target.value)}
          multiline
          rows={4}
          variant="filled"
          type="textarea"
        />
      </CampaignGroup>
    </Stack>
  )
}
