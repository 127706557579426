import { PresignedPost } from '@aws-sdk/s3-presigned-post'
import { HiddenFields } from '@tm/types/common/hiddenFields'
import { PublicConsentType } from '@tm/types/db/tables'
import { ThirdPartyCollectData } from '@tm/types/survey/entities'
import { Translation } from './i18n'
import { Step } from './step'
import { MetaData, SocialShare } from './survey'

export type ErrorCode = 'surveyNotFound' | 'surveyClosed' | 'invalidSurvey' | 'invalidData' | 'internalError' | string

export interface Failure {
  type: 'error'
  errorCode: ErrorCode
  message?: string
}

interface PostSurveyData {
  type: 'step'
  eventId: string
  answerId: string
  step: Step
  previewValues?: StepValues
  isCampaignSurvey?: boolean
}

export interface ThanksData {
  type: 'thanks'
  eventId: string
  answerId?: string
  thanks?: {
    title: Translation
    text: Translation
    redirect?: string
    thirdPartyCollect?: ThirdPartyCollectData
  }
  share?: SocialShare
  shareUrl?: string
}

export interface GetSurveyData {
  type: 'step'
  eventId: string
  answerId?: string
  features: string[]
  meta: MetaData
  step: Step
  surveyValues?: StepValues //Previously saved values
  conversionId?: string
  publicConsent: PublicConsentType
  isCampaignSurvey?: boolean
}

export interface SurveyData {
  eventId: string
  answerId?: string
  features: string[]
  meta: MetaData
  step: Step
}

export type PostSurveyResponse = PostSurveyData | ThanksData | Failure

export type GetSurveyResponse = GetSurveyData | Failure

export interface PostFileData {
  type: 'file'
  filename: string
  data: PresignedPost
}

export type PostFileResponse = PostFileData | Failure

export type CustomValue = string | string[] | number | boolean | null

export function isCustomObj(e: CustomValue | CustomValuesMap | undefined): e is CustomValuesMap {
  return typeof e === 'object' && e !== null && !Array.isArray(e)
}

export interface StepValuesIndex {
  custom?: CustomValuesMap

  [key: string]: CustomValue | CustomValuesMap | undefined //Make StepValues indexable
}

export interface StepValues extends StepValuesIndex {
  // Basic fields
  nps?: number
  stars?: number
  feedback?: string

  // Customer information
  name?: string
  email?: string
  location?: string
  organization?: string
  position?: string
  imageFilename?: string

  team?: string
  website?: string
  companyLogo?: string
  state?: string
  country?: string
  neighborhood?: string

  // Text
  comment?: string

  // Video
  commentVideo?: string
  noVideoRecord?: boolean

  // Consent / no consent
  publicConsent?: boolean
  noPublicConsent?: boolean

  //custom?: CustomValuesMap

  //[key: string]: CustomValue | CustomValuesMap | undefined //Make StepValues indexable
}

export interface CustomValuesMap {
  [key: string]: CustomValue
}

export interface ClientSurveyAnswer {
  eventId: string
  answerId?: string
  invitationId?: string
  source?: string
  sourceUrl?: string
  stepId: string
  stepValues: StepValues
  customParams?: Record<string, string>
  hiddenFields?: HiddenFields
  preview?: boolean
  previewValues?: StepValues
  progressDirection?: 'back' | 'forward'
}

export interface ClientUploadData {
  filename: string
  size: number
}
