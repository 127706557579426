import { useCallback, useEffect, useMemo, useState } from 'react'
import { ViewProps } from '../../types'

export const skipFieldNames = ['noPublicConsent', 'noVideoRecord', 'noNegativeContact'] as const
type SkipFieldName = typeof skipFieldNames[number]
export const isSkipFieldName = (name: string): name is SkipFieldName => skipFieldNames.includes(name as SkipFieldName)

/**
 * Hook to handle noPublicConsent or noVideoRecord skip checkbox
 * and then submit form
 */
export function useSkipButton(props: ViewProps) {
  const { state, dispatch, submitForm } = props
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const skipField = useMemo(() => {
    const fields = state.step.fields || []

    for (const field of fields) {
      if (isSkipFieldName(field.name)) return field
    }

    return null
  }, [state])

  const value = skipField && 'getValue' in state ? state.getValue(skipField) : undefined

  const handleSkipClick = useCallback(() => {
    if (!skipField) return
    dispatch({ type: 'set value', field: skipField, value: true })
    setShouldSubmit(true)
  }, [skipField, dispatch])

  /**
   * We need to wait that state updates with correct value for skip field
   * and then we can submit form
   */
  useEffect(() => {
    if (!shouldSubmit) return
    if (!value) return
    submitForm()
  }, [submitForm, shouldSubmit, value])

  return {
    skipField,
    handleSkipClick,
  }
}

export type SkipButtonState = ReturnType<typeof useSkipButton>
