import { GetSurveyData, GetSurveyResponse } from '@tm/shared-lib/src/api'
import { PublicConsentType } from '@tm/types/db/tables'
import { useCallback, useEffect, useReducer } from 'react'
import type { Action } from './actions/action'
import { InProgressStateImpl } from './states/inProgress'
import { LoadingStateImpl } from './states/loading'
import type { State } from './states/state'

const stateReducer = (state: State, action: Action): State => {
  // console.log({ state, action })
  if (action.type === 'loading get') {
    if (
      state.type === 'loading get' &&
      state.surveyId === action.data.surveyId &&
      state.answerId === action.data.answerId &&
      state.invitationId === action.data.invitationId &&
      state.source === action.data.source &&
      state.sourceUrl === action.data.sourceUrl &&
      state.overrideLang === action.data.overrideLang &&
      state.customParams === action.data.customParams
    ) {
      return state
    } else {
      return new LoadingStateImpl(action.data)
    }
  }
  return state.processAction(action)
}

interface UseSurveyOptions {
  invitationId?: string
  source?: string
  sourceUrl?: string
  answerId?: string
  overrideLang?: string
  initialSurvey?: GetSurveyData
  customParams?: Record<string, string>
  publicConsent?: PublicConsentType
}

function getInitialState(surveyId: string, options: UseSurveyOptions): LoadingStateImpl | InProgressStateImpl {
  if (options.initialSurvey)
    return new InProgressStateImpl({
      values: options.initialSurvey.surveyValues || {},
      surveyId,
      eventId: options.initialSurvey.eventId,
      step: options.initialSurvey.step,
      meta: options.initialSurvey.meta,
      features: options.initialSurvey.features,
      publicConsent: 'gdpr-compliant',
    }).processDefaultvalues()

  return new LoadingStateImpl({
    surveyId,
    invitationId: options.invitationId,
    source: options.source,
    sourceUrl: options.sourceUrl,
    answerId: options.answerId,
    overrideLang: options.overrideLang,
    publicConsent: options.publicConsent,
  })
}

type SurveyOutput = [state: State, dispatch: React.Dispatch<Action>, resetSurvey: () => void]

export function useSurvey(surveyId: string, options: UseSurveyOptions): SurveyOutput {
  const hasInitialSurvey = typeof options.initialSurvey !== 'undefined'

  const [state, dispatchAction] = useReducer(stateReducer, getInitialState(surveyId, options))

  const [resetFlag, toggleReset] = useReducer((state: boolean) => {
    return !state
  }, false)

  useEffect(() => {
    if (hasInitialSurvey) return

    const parts = []
    if (options.invitationId) {
      parts.push('i=' + options.invitationId)
    }
    if (options.answerId) {
      parts.push('a=' + options.answerId)
    }
    if (options.customParams) {
      for (const [key, value] of Object.entries(options.customParams)) {
        parts.push(`${key}=${value}`)
      }
    }
    if (window.location.search.includes('preview=1')) parts.push('preview=1')

    const query = parts.length > 0 ? '?' + parts.join('&') : ''
    dispatchAction({ type: 'loading get', data: { surveyId, ...options } })

    if (!process.env.API_DOMAIN) throw new Error('API_DOMAIN missing')
    fetch(`${process.env.API_DOMAIN}/collect/v2/survey/${surveyId}${query}`, {
      method: 'GET',
    }).then(
      (res: Response) => {
        res.json().then(
          (surveyData: GetSurveyResponse) => {
            dispatchAction({
              type: 'get response',
              data: surveyData,
            })
          },
          () => {
            dispatchAction({ type: 'loading error' })
            //Not json?
          }
        )
      },
      () => {
        dispatchAction({ type: 'loading error' })
        //Network error?
      }
    )
    return () => {
      //TODO: cancel fetch
    }
  }, [surveyId, options, dispatchAction, resetFlag, hasInitialSurvey])

  const resetSurvey = useCallback(() => {
    toggleReset()
  }, [toggleReset])

  return [state, dispatchAction, resetSurvey]
}
