import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import { alpha } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { CampaignValues } from '../../context/campaignContext'
import { useCampaign } from '../../hooks/useCampaign'

type CampaignProgressBarProps = {
  position: CampaignValues['progressBar']['position']
  // Used in editor to give inline step number
  overridePage?: number

  // ThanksView can override final page
  overrideFinalPage?: boolean
}

/**
 * Show progressbar for campaign
 * props.position will tell in where it's rendered and if it's not selected position, don't render
 */
export function CampaignProgressBar(props: CampaignProgressBarProps) {
  const { progressBar, primaryColor } = useCampaign()
  const { active, position, showNumbers, currentPage, totalPages, finalPage } = progressBar

  if (!active || position !== props.position) return null
  // If no pages, there's no progress
  if (!totalPages) return null

  const isFinalPage = props.overrideFinalPage || finalPage
  const page = props.overridePage ? props.overridePage : currentPage
  // When finalPage is reached (ie thanks page) show 100%
  const progressValue = isFinalPage ? 100 : ((page - 1) / totalPages) * 100
  const progressTextValue = `${Math.round(progressValue)}%`

  return (
    <Stack
      gap={1}
      alignItems="center"
      direction={props.position === 'before' ? 'column-reverse' : 'column'}
      mb={props.position === 'before' ? 3 : 0}
      mt={props.position === 'before' && showNumbers ? -1.5 : 0}>
      <LinearProgress
        value={progressValue}
        variant="determinate"
        sx={{
          width: 1,
          '.MuiLinearProgress-colorPrimary': {
            bgcolor: alpha(primaryColor, 0.24),
          },
          '.MuiLinearProgress-barColorPrimary': {
            bgcolor: primaryColor,
          },
        }}
      />
      {showNumbers && (
        <Typography variant="subtitle2" color="text.secondary">
          {progressTextValue}
        </Typography>
      )}
    </Stack>
  )
}
