import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button'
import Icon from '@trustmary/icons'
import React from 'react'
import { useSurveyState } from '../../hooks/useSurveyState'

export const CampaignBackButton = ({ isSubmitting, isCompact }: { isSubmitting?: boolean; isCompact?: boolean }) => {
  const [state] = useSurveyState()
  if (state.type !== 'in progress') return null
  if (state.step.stepNum === 1) return null
  if (state.thanksPage) return null
  return (
    <Button
      data-previous={true}
      disabled={isSubmitting}
      type="submit"
      color="primary"
      sx={{
        position: 'absolute',
        left: theme => (isCompact ? undefined : theme.spacing(2)),
        top: theme => (isCompact ? theme.spacing(2) : 'calc(50% - 20px)'),
        right: theme => (isCompact ? theme.spacing(2) : undefined),
        zIndex: 3,
        width: 40,
        height: 40,
      }}>
      <Icon icon={faArrowLeft} />
    </Button>
  )
}
